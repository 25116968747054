import type { AxiosResponse } from 'axios';
import { coreAxios } from '@/plugins/axios';
import { ProblemLog, StudentData } from '@/domain/ReportData/StudentData';
import {
  AssignmentDefinition,
  AssignmentSortByField,
  AssignmentScope,
  AssigneeType,
  AssignmentStats,
  AssignmentSettings,
} from '@/domain/Assignment';
import { LmsProviderType } from '@/domain/LmsProviderType';
import { CancelToken } from 'axios';
import { User } from '@/domain/User';
import { UserProfileDTO, transformUserProfileToUser } from '@/utils/user.util';
import { FolderMemberType } from '@/domain/Folder';
import type { DefinitionInclude, ObjectList } from './base.api';
import { AclPermissionType, AclResourceType } from '@/domain/Acls';
import { IsActiveFilterType } from '@/domain/State';
import {
  ActionGroupsType,
  StatisticsForType,
  StudentDataParams,
  StudentDataReturnType,
} from './sdata.api';
import { ProblemSetType } from '@/domain/ProblemSet';

const END_POINT = '/assignments';

//////////
// DTOs //
//////////

export interface AssignmentProperty {
  categoryName: string;
  categoryDescription: string;
  propertyKey: string;
  propertyDescription: string;
  propertyValue: string;
}

export interface AssignmentDefinitionDTO {
  scope: AssignmentScope;
  assigneeType: AssigneeType;
  xref: string;
  assigneeXref: string;
  ownerXref: string;
  groupContextXref: string;
  problemSetCeri: string;
  lmsProviderType: string;
  name: string;
  assignDate: number;
  releaseDate: number;
  dueDate: number;
  active: boolean;
  properties?: Array<AssignmentProperty>;
  skills: Array<string>;
  permissions?: AclPermissionType[];
}

/////////////////
// CAS Web API //
/////////////////

export interface AssignmentFilterAndSortParams {
  assignees?: Array<string>;
  courses?: Array<string>;
  isActive?: IsActiveFilterType;
  limit?: number;
  nextPageToken?: string;
  sortBy?: AssignmentSortByField;
  include?: Array<DefinitionInclude>;
  dueDate?: string;
  releaseDate?: string;
  assignDate?: string;
  skills?: string[];
  problemSetTypes?: ProblemSetType[];
}

const getAssignmentDefinitions = (
  params: AssignmentFilterAndSortParams,
  controller?: AbortController
): Promise<ObjectList<AssignmentDefinition>> => {
  return coreAxios
    .get(`${END_POINT}`, {
      params,
      signal: controller?.signal,
    })
    .then((result: AxiosResponse<ObjectList<AssignmentDefinitionDTO>>) => {
      return {
        data: result.data.data.map(transformAssignment),
        nextPageToken: result.data.nextPageToken,
        count: result.data.count,
      };
    });
};

export interface AssignmentDefinitionParams {
  details?: boolean;
  // Default ENABLED.
  isActive?: IsActiveFilterType;
}

// Active filters:    'ENABLED', 'DISABLED', or 'IGNORE'
// Details option:    true returns assignment's properties, defaults to false
const getAssignmentDefinition = (
  assignmentXref: string,
  params?: AssignmentDefinitionParams,
  cancelToken?: CancelToken
): Promise<AssignmentDefinition> => {
  return coreAxios
    .get(`${END_POINT}/${assignmentXref}`, {
      params: params,
      cancelToken,
    })
    .then((result: AxiosResponse<AssignmentDefinitionDTO>) => {
      return transformAssignment(result.data);
    });
};

// {
//   include?: Array<DataType>;
//   actions?: Array<ActionFilter>;
//   usersSubset?: Array<string>;
//   statisticsFor?: StatisticsFilter;
//   activeFilter?: IsActiveFilter;
// }
const getAssignmentReportData = (
  assignmentXref: string,
  params: Partial<StudentDataParams> = {}
): Promise<StudentData> => {
  if (!params.include) {
    params.include = [...Object.values(StudentDataReturnType)];
  }

  if (!params.actions) {
    params.actions = [
      ActionGroupsType.ASSIGNMENT_ACTIONS,
      ActionGroupsType.TIMER_ACTIONS,
      ActionGroupsType.PROBLEM_ACTIONS,
      ActionGroupsType.RESPONSE_ACTIONS,
      ActionGroupsType.TUTORING_REQUEST_ACTIONS,
    ];
  }

  if (!params.statisticsFor) {
    params.statisticsFor = [StatisticsForType.ALL_STUDENTS];
  }

  return coreAxios
    .get(`${END_POINT}/${assignmentXref}/sdata`, {
      params: {
        ...params,
      },
    })
    .then((result: AxiosResponse<StudentData>) => {
      return result.data;
    });
};

const deleteAssignmentProgress = (
  assignmentXref: string,
  studentXref: string
): Promise<void> => {
  return coreAxios.delete(
    `${END_POINT}/${assignmentXref}/sdata/${studentXref}`
  );
};

const getAssignmentAssignees = (
  assignmentXref: string,
  controller?: AbortController
): Promise<Array<User>> => {
  return coreAxios
    .get(`${END_POINT}/${assignmentXref}/assignees`, {
      signal: controller?.signal,
    })
    .then((res: AxiosResponse<UserProfileDTO[]>) => {
      return res.data.map(transformUserProfileToUser);
    });
};

// FIXME: Figure out how we can prevent Teacher from overwriting entire partLogData map
// including but not limited to prior responses. Support partial partLogData?
const updateStudentProblemLog = (
  assignmentXref: string,
  studentXref: string,
  problemLogId: number,
  payload: Pick<ProblemLog, 'continuousScore' | 'partLogData'>
): Promise<void> => {
  return coreAxios.patch(
    `${END_POINT}/${assignmentXref}/sdata/${studentXref}/pLogs/${problemLogId}`,
    payload
  );
};

const getAssignmentStats = (xref: string): Promise<AssignmentStats> => {
  return coreAxios.get(`${END_POINT}/${xref}/astats`).then((result) => {
    return result.data;
  });
};

const deleteAssignment = (
  xref: string,
  lmsProviderType?: LmsProviderType
): Promise<void> => {
  return coreAxios.delete(`${END_POINT}/${xref}`, {
    params: { lmsPtype: lmsProviderType?.id },
  });
};

export interface EditableAssignmentFields {
  name?: string;
  releaseDate?: number | null;
  dueDate?: number | null;
}

const updateAssignment = (
  xref: string,
  modifiedFields: EditableAssignmentFields,
  lmsProviderType?: LmsProviderType
): Promise<void> => {
  return coreAxios.patch(`${END_POINT}/${xref}`, modifiedFields, {
    params: { lmsPtype: lmsProviderType?.id },
  });
};

const uploadAssignmentScores = (
  xref: string,
  lmsProviderType?: LmsProviderType
): Promise<void> => {
  return coreAxios.post(`${END_POINT}/${xref}/scores/upload`, undefined, {
    params: { lmsPtype: lmsProviderType?.id },
  });
};

/////////////
// Helpers //
/////////////
function transformAssignment(
  assignment: AssignmentDefinitionDTO
): AssignmentDefinition {
  const settings: AssignmentSettings = {} as AssignmentSettings;

  const properties = assignment?.properties ?? [];
  //TODO: This needs to be changed. The backend currently sends these property values
  // as strings inside of a property object. We would rather they send a block of settings
  // similar to our AssignmentSettings interface (the actual types rather than strings)
  for (const property of properties) {
    // FIXME: add type to the AssignmentProperty and compare it
    if (
      property.propertyValue === 'true' ||
      property.propertyValue === 'false'
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      settings[property.propertyKey as keyof AssignmentSettings] =
        property.propertyValue === 'true';
    } else if (/^-?\d+$/.test(property.propertyValue)) {
      //It's a number

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      settings[property.propertyKey as keyof AssignmentSettings] = Number(
        property.propertyValue
      );
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      settings[property.propertyKey as keyof AssignmentSettings] =
        property.propertyValue;
    }
  }

  return {
    ...assignment,
    settings,
    dueDate: assignment.dueDate || null,
    lmsProviderType: LmsProviderType.findByName(
      assignment.lmsProviderType
    ) as LmsProviderType,
    memberType: FolderMemberType.ASSIGNMENT,
    resourceType: AclResourceType.ASSIGNMENT,
    permissions: assignment.permissions ?? [],
    // Internal processing only. Will not included in DTO if not set manually.
    createdAt: assignment.assignDate,
    updatedAt: assignment.assignDate,
  };
}

export {
  getAssignmentDefinitions,
  getAssignmentDefinition,
  getAssignmentReportData,
  deleteAssignmentProgress,
  updateStudentProblemLog,
  getAssignmentAssignees,
  getAssignmentStats,
  deleteAssignment,
  updateAssignment,
  uploadAssignmentScores,
  transformAssignment,
};
