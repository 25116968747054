import { render, staticRenderFns } from "./OverTimeReportParent.vue?vue&type=template&id=13b890fc&scoped=true&"
import script from "./OverTimeReportParent.vue?vue&type=script&lang=ts&setup=true&"
export * from "./OverTimeReportParent.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13b890fc",
  null
  
)

export default component.exports